<template>
  <article class="Persona">
    <link-router
      class-names="image-link"
      :path="linkPath"
    >
      <img
        :src="imageUrl"
        :alt="$t(userTitle)"
      >
    </link-router>

    <div class="content">
      <h3 class="Headline is-size-3">
        <small>{{ $t('iAm') }}</small>

        {{ $t(userTitle) }}
      </h3>

      <link-router
        class-names="Button"
        :path="linkPath"
      >
        {{ $t('register') }}
      </link-router>
    </div>
  </article>
</template>

<script>
export default {
  name: 'PersonaItem',

  props: {
    userRole: {
      type: String,
      default: ''
    }
  },

  computed: {
    userTitle () {
      return `userRoles.${this.userRole}`
    },

    linkPath () {
      return `reg-${this.userRole}`
    },

    imageUrl () {
      return require(`@/assets/images/avatar-${this.userRole}.png`)
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/_component-imports';

.Persona {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  margin-bottom: 60px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include media('>=md') {
    flex-direction: column;
    text-align: center;
    margin-bottom: 0;
  }

  &.is-on-home {
    @include animation-out;
    min-width: 100px;
    width: 100%;

    &.is-visible {
      opacity: 1;
      transform: translate3d(0,0,0);
    }

    @include media('>=md') {
      width: 210px;
    }

    @include media('>=lg') {
      width: 290px;
    }
  }

  .image-link {
    width: 34%;

    img {
      width: 100%;
    }

    @include media('>=sm') {
      width: 30%;
    }

    @include media('>=md') {
      width: 100%;
    }
  }

  .content {
    padding-left: 30px;
    align-self: center;

    @include media('>=sm') {
      padding-left: 50px;
    }

    @include media('>=md') {
      padding-left: 0;
    }
  }

  .Headline {
    margin: 1em 0;
  }
}
</style>
