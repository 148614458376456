export function sortListByLabel (list) {
  if (!list) {
    return list
  }

  return list.sort(function (a, b) {
    // sort by the value comparison if there is no labrel, eg this might be a string or number.
    if (!a.label || !b.label) {
      return a > b
    }

    return ('' + a.label).localeCompare(b.label)
  })
}

export function upperCaseAfterFirstDash (str) {
  const index = str.indexOf('-')
  if (index >= 0) {
    const pt2 = str.substring(index).toUpperCase()
    str = str.substring(0, index) + pt2
  }
  return str
}
