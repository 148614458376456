import axios from 'axios'

export let appSettings = {}

let haveSettingsBeenLoaded = false

export async function loadSettings () {
  if (haveSettingsBeenLoaded) return

  let response = await axios.get(`${process.env.VUE_APP_LOCALE_PATH}/settings/settings.json`)
  appSettings = response.data
  haveSettingsBeenLoaded = true
}

export function isFeatureEnabled (feature) {
  if (!appSettings) {
    throw new Error('Appsettings not loaded - cannot check if feture is enabled: ' + feature)
  }

  // for now the feature is enabled - no other settings/info
  return appSettings.features && appSettings.features[feature]
}

export const accountTypes = {
  // basic translates to Online User in the translation. But since I do not belive 'Online User' will stay the real name
  // I decided to use the term we had until now...
  BASIC: 'basic',
  RECIPIENT: 'recipient',
  CAREGIVER: 'caregiver',
  PROFESSIONAL: 'professional'
}

export const accountStates = {
  NONE: 'none',
  PENDING: 'pending',
  VERIFIED: 'verified'
}
