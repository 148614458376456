import { createStore } from 'vuex'
import user from './modules/user'
import profile from './modules/profile'

const store = createStore({
  state () {
    return {
      isApiCalling: false,
      isLoading: false
    }
  },

  getters: {
    isApiCalling: state => state.isApiCalling,
    isLoading: state => state.isLoading
  },

  mutations: {
    setApiCalling (state, val) {
      state.isApiCalling = val
    },
    setLoading (state, val) {
      state.isLoading = val
    }
  },

  modules: {
    user,
    profile
  }
})

export default store
