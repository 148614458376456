<template>
  <span
    v-show="show"
    class="SimpleSpinner"
    :class="classNames"
  >
    <span />
    <span />
    <span />
  </span>
</template>

<script>
export default {
  name: 'SimpleSpinner',

  props: {
    show: {
      type: Boolean,
      default: false
    },

    classNames: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.SimpleSpinner,
.SimpleSpinner > span {
  position: relative;
  box-sizing: border-box;
}

.SimpleSpinner {
  display: inline-block;
  font-size: 0;
  color: #fff;
  width: 54px;
  height: 18px;

  &.la-dark {
    color: #333;
  }

  &.is-small {
    width: 26px;
    height: 8px;

    > span {
      width: 4px;
      height: 4px;
      margin: 2px;
    }
  }

  &.is-red {
    color: $color-red-500;
  }

  &.is-registration {
    display: inline-block;
    width: 72px;
    height: 28px;

    > span {
      width: 12px;
      height: 12px;
      margin: 6px;
    }
  }

  &.is-big {
    width: 108px;
    height: 36px;

    > span {
      width: 20px;
      height: 20px;
      margin: 8px;
    }
  }

  > span {
    display: inline-block;
    background-color: currentColor;
    border: 0 solid currentColor;
    width: 10px;
    height: 10px;
    margin: 4px;
    border-radius: 100%;
    opacity: 0;
    animation: ball-fall 1s ease-in-out infinite;

    &:nth-child(1) {
      animation-delay: -200ms;
    }

    &:nth-child(2) {
      animation-delay: -100ms;
    }

    &:nth-child(3) {
      animation-delay: 0ms;
    }
  }
}

@keyframes ball-fall {
  0% {
    opacity: 0;
    transform: translateY(-145%);
  }
  10% {
    opacity: .5;
  }
  20% {
    opacity: 1;
    transform: translateY(0);
  }
  80% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: .5;
  }
  100% {
    opacity: 0;
    transform: translateY(145%);
  }
}
</style>
