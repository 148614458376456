function throttle (fn, wait) {
  let time = Date.now()

  return () => {
    if ((time + wait - Date.now()) < 0) {
      fn()
      time = Date.now()
    }
  }
}

export default {
  beforeMount (el, binding, vnode) {
    const path = el.querySelector('path')
    const pathLength = path.getTotalLength()

    path.style.strokeDasharray = pathLength + ' ' + pathLength
    path.style.strokeDashoffset = pathLength
  },

  mounted (el, binding) {
    const path = el.querySelector('path')
    const pathLength = path.getTotalLength()
    const docHeight = document.documentElement.clientHeight

    window.addEventListener('scroll', throttle(e => {
      const rect = el.getBoundingClientRect()
      // what % down is it?
      const scrollPercentage = (docHeight / 2 - rect.top) / rect.height

      // Length to offset the dashes
      let drawLength = Math.max(0, pathLength * scrollPercentage)
      drawLength = Math.min(pathLength, drawLength)
      // Draw in reverse
      path.style.strokeDashoffset = Math.max(pathLength - drawLength, 0)
    }, 100))
  }
}
