<template>
  <div class="Benefit">
    <i
      v-if="icon"
      :class="['icon', icon]"
    />

    <p class="content">
      <strong v-text="title" />

      <span
        v-for="(text, index) in descriptions"
        :key="index"
        v-text="text"
      />
    </p>
  </div>
</template>

<script>
export default {
  name: 'BenefitItem',

  props: {
    icon: String,
    title: String,
    descriptions: {
      type: Array,
      default: () => []
    }
  }
}

</script>

<style lang="scss">
@import '../../styles/_component-imports';

.Benefit {
  display: flex;
  flex-direction: row;
  // max-width: 240px;
  margin: 0;
  padding: 28px;
  text-align: left;
  margin-bottom: 40px;

  @include media('>=xs') {
    margin-bottom: 60px;
  }

  @include media('>=md') {
    flex-direction: column;
    max-width: 240px;
    margin-bottom: 0;
    padding: 0;
    text-align: center;
  }

  p {
    margin: 0;
    margin-left: 28px;

    @include media('>=md') {
      margin-left: 0;
    }
  }

  i {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 84px;
      width: 84px;
      border-radius: 50%;
      background-color: $color-grey-100;
      transform: translate(-50%, -2px);
      z-index: -1;
    }
  }

  .icon::before {
    /* original size was 80 px */
    font-size: 54px;
    border: 3px solid black;
    padding: 8px;
  }

  strong {
    display: block;
    font-size: 1.32em;
    line-height: 1.33;
    margin: 0 0 1em;
    // margin-bottom: 1.75em;

    @include media('>=md') {
      margin: 0.5em 0 1em;
      padding: 0 1.7em;
    }
  }

  span {
    display: block;
    color: $color-grey-600;
    // line-height: 1.75;
    line-height: 1.5;

    ~ span {
      // margin-top: 1em;
      margin-top: 0.5em;
    }
  }
}
</style>
