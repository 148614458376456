<template>
  <router-link
    :class="classNames"
    :to="{
      name: path,
      params: {
        lang: currentLocale
      }
    }"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  name: 'LinkRouter',

  props: {
    classNames: {
      type: String,
      default: ''
    },

    path: {
      type: String,
      required: true
    },

    locale: {
      type: String,
      default: 'en'
    }
  },

  computed: {
    currentLocale () {
      const locale = this.$i18n.locale
      // 'en' is default and is not added to the URL
      // return locale !== 'en' ? locale : null
      return locale
    }
  }
}
</script>
