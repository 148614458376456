<template>
  <main id="app">

      <router-view :key="$i18n.locale" v-slot="{Component}">
          <component :is="Component" />
      </router-view>
    <div class="overlay-left" />
    <div class="overlay-right" />
    <overlay-loader />
    <loading />
  </main>
</template>

<script>
import OverlayLoader from '@/components/OverlayLoader.vue'
import Loading from './components/Loading.vue'
import { loadSettings } from './settings'

export default {
  name: 'App',

  metaInfo: {
    title: 'Welcome to the world of MED-EL',
    titleTemplate: '%s | MyMED-EL Portal'
  },

  components: {
    OverlayLoader,
    Loading
  },

  async mounted () {
    await loadSettings()
  }
}
</script>

<style lang="scss">
@import './styles/_component-imports';

.overlay-left {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background: $color-grey-300;
  z-index: 100;
  transition-duration: 0.5s;
  transition-property: transform;
  overflow: hidden;
  width: 100vw;
  transform: scale3d(0,1,1);
  transform-origin: right center;
}

.overlay-right {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: $color-grey-300;
  transition-duration: 0.5s;
  transition-property: transform;
  width: 100vw;
  transform: scale3d(0,1,1);
  transform-origin: left center;
  z-index: 100;
}

.overlay-left-full-enter ~ .overlay-right {
  transform: scale3d(1,1,1);
}

.overlay-left-full-enter-active ~ .overlay-right,
.overlay-left-full-leave-active ~ .overlay-right {
  transform: scale3d(1,1,1);
}

.overlay-left-full-enter-active ~ .overlay-right {
  transition-timing-function: $transition-curve-deceleration;
}

.overlay-left-full-enter-active ~ .overlay-right,
.overlay-left-full-enter-to ~ .overlay-right {
  display: none;
}

.overlay-left-full-enter-to ~ .overlay-left {
  transform: scale3d(0,1,1);
}

.overlay-left-full-leave-active ~ .overlay-left {
  transition-timing-function: $transition-curve-acceleration;
}

.overlay-left-full-enter ~ .overlay-left,
.overlay-left-full-enter-active ~ .overlay-left,
.overlay-left-full-enter-to ~ .overlay-left {
  transition-duration: unset !important;
  transform: scale3d(1,1,1);
}

.overlay-left-full-enter-active,
.overlay-left-full-leave-active {
  transition-duration: 0.35s;
}
</style>
