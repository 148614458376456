import { createI18n } from 'vue-i18n/index'
import axios from 'axios'
import defaultMessages from '@/locales/default.json' // default messages, not editable

// set default locale to 'default'
// therefore fallback is always save because a admin user cant edit them
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'default',
  allowComposition: true,
  messages: {
    default: defaultMessages
  }
})

const loadedLanguages = ['default']

const rightToLeftLanguages = ['ar', 'he', 'fa']

function setDocumentDirectionPerLocale (locale) {
  const isRtlLanguage = rightToLeftLanguages.indexOf(locale.toLowerCase()) !== -1
  // we could set the dir on the document, but his would mess with our whole layout.
  // document.dir = isRtlLanguage ? 'rtl' : 'ltr'
  // if rtl changed, we need to check if we need to add or remove the custom css.
  var cssId = 'rtlStylesheet'
  var rtlCssElement = document.getElementById(cssId)
  if (!rtlCssElement && isRtlLanguage) {
    var head = document.getElementsByTagName('head')[0]
    var link = document.createElement('link')
    link.id = cssId
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = '/css/rtl.css'
    link.media = 'all'
    head.appendChild(link)
  } else if (rtlCssElement && !isRtlLanguage) {
    // remove the element if it was there, because it is ltr again.
    rtlCssElement.parentNode.removeChild(rtlCssElement)
  }
}

// change locale and set 'lang' attribute
function setI18nLanguage (lang) {
  i18n.global.locale = lang
  document.querySelector('html').setAttribute('lang', lang)

  try {
    if (window.OneTrust) {
      window.OneTrust.changeLanguage(lang)
    }
  } catch (e) {
    // do nothing because we do not care about the language of the cookie pro so much
  }

  setDocumentDirectionPerLocale(lang)
  return lang
}

function getLanguageInformation (lang) {
     // language might be en-US, de-AT etc, which is accurate, but we right now do not support.
    // so in this case, get rid of the region setting.
    const langParts = lang.split('-')
    const languageCode = langParts[0]

    // try to extract the country information, e.g. if original string was en-US we want to get US.
    let countryCode = ''
    if (langParts.length > 1) {
      countryCode = langParts[1]
    }

    return {
      // the 2 letter language code, eg. en, de etc.
      languageCode: languageCode,
      // the country, which is appended to the countrycode, eg en_US or en-GB
      countryCode: countryCode,
      rawString: lang
    }
}

async function tryLoadLanguageFileAndSetUpLanguage (lang) {
  var msgs = await axios.get(`${process.env.VUE_APP_LOCALE_PATH}/${lang}.json`)
  // on our cdn, for resources that donot exist, we dfeault back to the index.html
  // so we won't get an error here, but the response is not what we expect it to be.
  if (!msgs.data || typeof msgs.data === 'string') {
    throw new Error('Response seems to be html and not json.')
  }
  // we stole everything from: https://medium.com/i18n-and-l10n-resources-for-developers/the-ultimate-guide-to-vue-localization-with-vue-i18n-bf98b1d40c65
  // it also includes information regarding pluralisation, numberformats, dateformats etc.
  i18n.global.setLocaleMessage(lang, msgs.data)
  loadedLanguages.push(lang)
  setI18nLanguage(lang)
}

// async handler to load language files
export async function loadLanguageAsync (lang) {
  setDocumentDirectionPerLocale(lang)
  if (i18n.locale === lang) return Promise.resolve(lang)
  if (loadedLanguages.includes(lang)) return Promise.resolve(setI18nLanguage(lang))

  const languageInformation = getLanguageInformation(lang)

  // if there is a country information, try to load the country specific language, e.g. en-US
  if (languageInformation.countryCode) {
    try {
      await tryLoadLanguageFileAndSetUpLanguage(languageInformation.rawString)
      return
    } catch {
      // ignore error - we could not load the file - it might not exist
    }
  }

  try {
    await tryLoadLanguageFileAndSetUpLanguage(languageInformation.languageCode)
  } catch {
    i18n.global.locale = 'en' // show default on error
  }
}

export function getOptionsAsObjectFromTranslation (translationKey) {
  const entries = i18n.global.tm(translationKey)
  return Object.keys(entries).map(key => {
    return {
      code: key,
      label: entries[key]
    }
  })
}

export function getOptionsAsTextFromTranslation (translationKey) {
  const entries = i18n.global.t(translationKey)
  return Object.keys(entries).map(key => {
    return entries[key]
  })
}

export default i18n
