/* eslint-disable */
// eslint needs to be disabled as it wants import reorder that would break the app
// polyfill
require('intersection-observer')

// application configs
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './translations'
import SweetModal from 'sweet-modal-vue-3/dist/sweet-modal-vue-3.es'
import LinkRouter from '@/components/partials/LinkRouter.vue'
import VueObserveVisibility from 'vue-observe-visibility'
import VueScrollTo from 'vue-scrollto'
import VueParallaxJs from 'vue-parallax-js'
import { Dropdown } from 'floating-vue'
// enable scss compiling for global css file
import vSelect from 'vue-select'
import './styles/main.scss'
import 'floating-vue/dist/style.css'
import { auth0 } from './auth/auth0'


// according to https://vuejs.org/v2/api/#Global-Config
// this is true in dev mode and false in production, so unless we do not want this on production
// leave it as is.
// Vue.config.devtools = true
// Vue.config.productionTip = false

const app = createApp(App)
app.use(i18n)
app.use(router)
app.use(auth0)
app.use(store)
app.use(VueScrollTo)
app.use(VueObserveVisibility)
app.use(VueParallaxJs)
app.use(SweetModal)

app.component('VDropdown', Dropdown)
app.component('v-select', vSelect)
app.component('link-router', LinkRouter)

app.mount('#app')