<template>
  <transition name="fade-loader" appear>
    <div v-if="show" class="OverlayLoader">
      <div class="content">
        <simple-spinner :show="true" class-names="is-red is-big" />

        <p class="Headline is-size-4">
          {{ $t('processDurationInfoMessage') }}
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
import SimpleSpinner from '@/components/SimpleSpinner.vue'

export default {
  name: 'OverlayLoader',

  components: {
    SimpleSpinner
  },

  computed: {
    show () {
      return this.$store.getters.isApiCalling
    }
  }
}
</script>

<style lang="scss">
@import '../styles/_component-imports';

.OverlayLoader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 100;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 320px;
  }

  .SimpleSpinner {
    margin-bottom: 30px;
  }
}

.fade-loader-enter-active,
.fade-loader-leave-active {
  transition: opacity 0.2s $transition-curve-acceleration;
  will-change: opacity;
}

.fade-loader-enter,
.fade-loader-leave-to {
  opacity: 0;
}

@keyframes draw {
  0% {
    stroke-dashoffset: 0;
  }
}
</style>
