<template>
  <div class="HomeView">
    <email-verified-message ref="emailVerifiedMessage" />
    <section class="Hero" ref="hero">
      <div class="HeroVideo">
        <video autoplay playsinline loop muted ref="video">
          <source
            src="@/assets/videos/Greatest-Gift_mymedel_1080p.webm"
            type="video/webm"
            media="all and (min-width: 481px)"
          >
          <source
            src="@/assets/videos/Greatest-Gift_mymedel_1080p.mp4"
            type="video/mp4"
            media="all and (min-width: 481px)"
          >
          <source
            src="@/assets/videos/Greatest-Gift_mymedel_mobile.mp4"
            type="video/mp4"
            media="all and (max-width: 480px)"
          >
        </video>
      </div>

      <img
      src="@/assets/images/medel-logo.svg"
      alt="My Medel Logo"
      class="medel-logo"
      >
      <VDropdown
        theme="dropdown"
        placement="bottom-end"
        popover-class="is-on-home"
        ref="languagePopover"
      >
        <div class="language-switch">
          {{ currentLanguage }}
        </div>

        <template #popper>
          <ul class="popper-linklist">
            <li
              v-for="lang in languages"
              :key="lang.code"
              class="d-block align-center"
            >
              <button
                class="d-block"
                type="button"
                v-text="lang.label"
                @click="changeLanguage(lang.code)"
              />
            </li>
          </ul>
        </template>
      </VDropdown>

      <div class="container" ref="heroContent">
        <h1
          class="Headline is-size-1 is-white gsap-f-bottom"
          v-t="'pages.home.hero.title'"
        />
        <div style="color:white">
        </div>
        <button
          class="Button is-simple gsap-f-bottom "
          type="button"
          v-t="'login.label'"
          @click="openLogin"
        />

        <button
          class="ButtonLink text-white has-border-b d-block gsap-f-bottom"
          type="button"
          v-t="'pages.home.hero.label'"
          @click="scrollToUsers"
        />
      </div>

      <button
        type="button"
        class="arrow"
        @click="scrollToBottom"
      >
        <img src="@/assets/images/arrow.png" alt="Scroll down">
      </button>
    </section>

    <!-- intro -->
    <section class="Intro" id="home-intro">
      <div class="container">
        <img
          src="@/assets/images/medel-logo.svg"
          alt="My Medel Logo"
          class="logo gsap-f-bottom"
          v-observe-visibility="gsapOptions(gFadeInBottom, 1)"
        >

        <p
          class="teaser Headline is-size-3 gsap-f-bottom"
          v-observe-visibility="gsapOptions(gFadeInBottom, 1)"
          v-t="'pages.home.intro'"
        />
      </div>
    </section>

    <!-- first stroke, benefits -->
    <section class="container-path">
      <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 139.7 1317" style="enable-background:new 0 0 139.7 1317;" xml:space="preserve" v-bind:svg-inline="''" v-bind:class="'path-mobile is-first'" v-path-scroll="" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
	<defs>
		<linearGradient id="PathMobilus_2_" gradientUnits="userSpaceOnUse" x1="-224.3156" y1="1078.077" x2="-224.3156" y2="1078.077" gradientTransform="matrix(132 0 0 -1310.33 29740 1413938.625)">
            <stop offset="0%" stop-color="#fff" stop-opacity="0"/>
            <stop offset="1%" stop-color="#f5d3da"/>
            <stop offset="2%" stop-color="#ebaab6"/>
            <stop offset="4%" stop-color="#e28496"/>
            <stop offset="5%" stop-color="#da637a"/>
            <stop offset="7%" stop-color="#d44863"/>
            <stop offset="9%" stop-color="#cf3250"/>
            <stop offset="11%" stop-color="#cb2142"/>
            <stop offset="13%" stop-color="#c81537"/>
            <stop offset="17%" stop-color="#c60e32"/>
            <stop offset="27%" stop-color="#c60c30"/>
            <stop offset="37%" stop-color="#c60c30"/>
            <stop offset="100%" stop-color="#c60c30"/>
		</linearGradient>
	</defs>

	<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
		<path d="M131,0v89v38.8c0,18-14.4,32.7-32.5,33H30c-14.7,0-26.7,11.8-27,26.5V1039
			c0,14.7,11.8,26.8,26.5,27h68c18,0,32.7,14.4,33,32.4V1302" stroke="url(#PathMobilus_2_)" stroke-width="6"/>
	</g>
</svg>

      <svg viewBox="0 0 1440 1664" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" v-bind:svg-inline="''" v-bind:class="'path-desktop is-first'" v-path-scroll="" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
    <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="13.9324482%" id="linearGradient-1">
            <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
            <stop stop-color="#C60C30" offset="100%"></stop>
        </linearGradient>
    </defs>

    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M720,0 L720,291 L720,291 L720,291 C719.774871,338.588599 681.133287,377.047812 633.544156,377.047812 L440.859375,377.047812 L440.859375,377.047812 L70.8279998,377.047812 C35.0244841,377.047812 6,406.072296 6,441.875812 L6,441.875812 L6,716 C6,748.5848 32.4151998,775 65,775 L1148,775 L1375,775 C1407.5848,775 1434,801.4152 1434,834 L1434,1108.12419 L1434,1108.12419 C1434,1143.9277 1404.97552,1172.95219 1369.172,1172.95219 L991.703848,1172.95219 L991.703848,1172.95219 L806.455844,1172.95219 C758.866713,1172.95219 720.225129,1211.4114 720,1259 L720,1259 L720,1259 L720.5,1259 L720.5,1664" id="desktop-path-1" stroke="url(#linearGradient-1)" stroke-width="12"></path>
    </g>
</svg>
      <article class="Benefits">
        <h2
          class="Headline is-size-2 gsap-f-bottom"
          v-observe-visibility="gsapOptions(gFadeInBottom, 1)"
          v-t="'pages.home.benefits.title'"
        />

        <ul class="list">
          <li
            v-for="(value, index) in benefits"
            :key="index"
            class="gsap-f-bottom"
            v-observe-visibility="{ callback: addVisibility, throttle: 300, once: true, intersection: { treshold: 1 } }"
          >
            <benefit-item :icon='value.icon' :title='value.title' :descriptions='value.description' />
          </li>
        </ul>
      </article>
    </section>

    <!-- mockup -->
    <section class="Mockups">
      <div class="container">
        <h2
          class="Headline is-size-2 gsap-f-bottom"
          v-observe-visibility="gsapOptions(gFadeInBottom, 1)"
          v-t="'pages.home.devices'"
        />
      </div>

      <div class="container has-mockups p-relative">
        <img
          src="@/assets/images/devices/tablets.png"
          alt="My Medel Portal"
          class="mockup gsap-f"
          v-observe-visibility="{ callback: addVisibility, throttle: 300, once: true, intersection: { treshold: 1 } }"
        >
        <img
          src="@/assets/images/devices/notebooks.png"
          alt="My Medel Portal"
          class="mockup gsap-f is-fixed"
          v-observe-visibility="{ callback: addVisibility, throttle: 300, once: true, intersection: { treshold: 1 } }"
        >
        <img
          src="@/assets/images/devices/mobile.png"
          alt="My Medel Portal"
          class="mockup gsap-f is-fixed"
          v-observe-visibility="{ callback: addVisibility, throttle: 300, once: true, intersection: { treshold: 1 } }"
        >
        <img
          src="@/assets/images/devices/rondo.png"
          alt="My Medel Portal"
          class="mockup gsap-f is-fixed"
          v-observe-visibility="{ callback: addVisibility, throttle: 300, once: true, intersection: { treshold: 1 } }"
        >
        <img
          src="@/assets/images/devices/sonnet.png"
          alt="My Medel Portal"
          class="mockup gsap-f is-fixed"
          v-observe-visibility="{ callback: addVisibility, throttle: 300, once: true, intersection: { treshold: 1 } }"
        >
      </div>
    </section>

    <!-- professional / recipient -->
    <section class="Users">
      <div class="path-container p-relative">
        <div class="path-wrapper">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 510 713" style="enable-background:new 0 0 510 713;" xml:space="preserve" v-bind:svg-inline="''" v-bind:class="'path-desktop is-last is-left'" v-path-scroll="" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
	<defs>
		<linearGradient id="path-desktop-bottom-left" gradientUnits="userSpaceOnUse" x1="-41.5843" y1="776.8" x2="-41.5843" y2="777.8" gradientTransform="matrix(-498 0 0 -713 -20454 554571.375)">
			<stop offset="0" style="stop-color:#F0F0F0"/>
			<stop offset="0.1041" style="stop-color:#C60C30"/>
			<stop offset="0.9301" style="stop-color:#C60C30"/>
			<stop offset="0.9705" style="stop-color:#680000"/>
			<stop offset="1" style="stop-color:#000000"/>
		</linearGradient>
	</defs>

	<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
		<path id="Links" stroke="url(#path-desktop-bottom-mid)" stroke-width="12" d="M504,0v440l0,0l0,0c-0.3,47.7-39.1,86.2-86.8,86.2h-93.7l0,0H106c-55.2,0-100,44.8-100,100V713"/>
	</g>
</svg>
          <svg x="0" y="0" viewBox="0 0 12 713" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" v-bind:svg-inline="''" v-bind:class="'path-desktop is-last is-mid'" v-path-scroll="" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
    <defs>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="path-desktop-bottom-mid">
            <stop stop-color="#F0F0F0" offset="0%"></stop>
            <stop stop-color="#C60C30" offset="10.406989%"></stop>
            <stop stop-color="#C60C30" offset="93.0111276%"></stop>
            <stop stop-color="#680000" offset="97.0516756%"></stop>
            <stop stop-color="#000000" offset="100%"></stop>
        </linearGradient>
    </defs>

    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M6,0 L0,713" stroke="url(#path-desktop-bottom-mid)" stroke-width="24"></path>
    </g>
</svg>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 510 713" style="enable-background:new 0 0 510 713;" xml:space="preserve" v-bind:svg-inline="''" v-bind:class="'path-desktop is-last is-right'" v-path-scroll="" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">

	<defs>
		<linearGradient id="path-desktop-bottom-right" gradientUnits="userSpaceOnUse" x1="-41.4157" y1="776.8" x2="-41.4157" y2="777.8" gradientTransform="matrix(498 0 0 -713 20880 554571.375)">
			<stop offset="0" style="stop-color:#F0F0F0"/>
			<stop offset="0.1041" style="stop-color:#C60C30"/>
			<stop offset="0.9301" style="stop-color:#C60C30"/>
			<stop offset="0.9705" style="stop-color:#680000"/>
			<stop offset="1" style="stop-color:#000000"/>
		</linearGradient>
	</defs>

	<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
		<path stroke="url(#path-desktop-bottom-right)" stroke-width="12" d="M6,0v440l0,0l0,0c0.3,47.7,39.1,86.2,86.8,86.2h93.7l0,0H404c55.2,0,100,44.8,100,100V713"/>
	</g>
</svg>
          <svg viewBox="0 0 222 1012" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" v-bind:svg-inline="''" v-bind:class="'path-mobile is-last'" v-path-scroll="" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
    <defs>
        <linearGradient x1="50%" y1="13.647757%" x2="50%" y2="0%" id="lin-grad-mobile-2">
            <stop stop-color="#C70E32" offset="0%"></stop>
            <stop stop-color="#680000" offset="86.6474873%"></stop>
            <stop stop-color="#000000" offset="100%"></stop>
        </linearGradient>
    </defs>

    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M107.5,0 L107.5,310 L107.5,358.645965 L107.715958,381.668963 C107.871365,398.236777 94.5664815,411.793626 77.9986679,411.949034 C77.9048737,411.949913 77.8110758,411.950353 77.7172775,411.950353 L33,411.950353 C16.4314575,411.950353 3,425.381811 3,441.950353 L3,457 L3,457 L3,1012" stroke="url(#lin-grad-mobile-2)" stroke-width="6"></path>
    </g>
</svg>
        </div>
      </div>

      <h2
        class="Headline is-size-2 gsap-f-bottom"
        v-observe-visibility="gsapOptions(gFadeInBottom, 1)"
        v-t="'pages.home.matchNeeds'"
      />
      <div
        id="register"
        class="persona-wrapper d-flex container"
      >
      <persona-item
          v-for="(persona, index) in userTypes"
          :key="index"
          :user-role="persona"
          class="is-on-home gsap-f-bottom"
          v-observe-visibility="{ callback: addVisibility, throttle: 300, once: true, intersection: { treshold: 1.0 } }"
        />
      </div>
    </section>

    <the-portal-footer />

    <sweet-modal
      ref="loginError"
      icon="error"
      @close="closeErrorModal()"
    >
      <div>
        <div v-html="$tm('login.error')" />

        <p>
          <button type="button" class="Button" @click="logout">
            {{ $t('form.logout') }}
          </button>
        </p>
      </div>
    </sweet-modal>

    <sweet-modal
      ref="maintenanceModal"
      icon="info"
      hide-close-button
      blocking
      overlay-theme="dark"
    >
      <div class="termsOfUseContent">
        <h1 class="Headline is-size-4">The site is currently undergoing maintenance, please come back later</h1>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import PersonaItem from '@/components/partials/PersonaItem.vue'
import BenefitItem from '@/components/partials/BenefitItem.vue'
import ThePortalFooter from '@/components/ThePortalFooter.vue'
import PathScroll from '@/directives/PathScroll'
import localeMessages from '@/mixins/localeMessages'
import { gsap } from 'gsap'
import { appSettings } from '@/settings'
import EmailVerifiedMessage from '@/components/EmailVerifiedMessage.vue'
import * as utils from '../utils.js'
import { login } from '../auth/auth0'

export default {
  name: 'Home',

  metaInfo () {
    return {
      title: this.$i18n.t('pages.home.meta.title')
    }
  },

  mixins: [ localeMessages ],

  components: {
    EmailVerifiedMessage,
    PersonaItem,
    BenefitItem,
    ThePortalFooter
  },

  directives: {
    PathScroll
  },

  data: function () {
    return {
      isAuthenticated: {}
    }
  },

  computed: {
    benefits () {
      return this.$tm('pages.home.benefits.items')
    },

    dynamoDbError () {
      return this.$store.getters['user/hasError']
    },

    currentLanguage () {
      const entries = appSettings.languages
      let language = 'en'
      try {
        language = Object.keys(entries).find(item => {
          return item === this.$i18n.locale
        }) || this.$i18n.locale
      } catch (error) {
      }
      return language || 'en'
    },

    userTypes () {
      return ['recipient', 'caregiver', 'professional']
    },

    languages () {
      const entries = appSettings.languages

      return utils.sortListByLabel(Object.keys(entries)
        .filter(item => item !== this.$i18n.locale)
        .map(key => ({
          code: key,
          label: entries[key]
        })))
    }
  },

  mounted () {
    this.isAuthenticated = this.$auth0.isAuthenticated
    this.scrollUp()
    if (appSettings.maintenanceMode === true) {
      this.$refs.maintenanceModal.open()
    }
    if (this.$refs.emailVerifiedMessage.shouldShow()) {
      this.$refs.emailVerifiedMessage.open()
    }
    const isRegistrationLink = this.$route.hash === '#register'
    this.gFadeHeadline(!isRegistrationLink).then(() => {
      if (isRegistrationLink) {
        this.scrollToUsers()
      }
    })
  },

  watch: {
    isAuthenticated () {
      if (this.isAuthenticated) {
        this.redirectToDashBoard()
      }
    },
    dynamoDbError (val) {
      if (val) this.openErrorModal()
    }
  },

  methods: {
    redirectToDashBoard () {
      const language = this.$store.getters['user/language'] || this.$i18n.locale
      this.$router.push({ name: 'dashboard', params: { lang: language }, query: this.$route.query })
    },
    changeLanguage (code) {
      this.$refs.languagePopover.hide()
      this.$router.push({ name: this.$route.name, params: { lang: code }, query: this.$route.query })
      this.$i18n.locale = code
    },

    openLogin () {
      login()
    },

    openErrorModal () {
      this.$refs.loginError.open()
    },

    closeErrorModal () {
      this.authLockError = false
      this.$store.commit('user/setError', false)
    },

    gFadeHeadline (isAnimated) {
      const t = gsap.timeline()
      const h = this.$refs.heroContent.querySelector('.Headline')
      const lb = this.$refs.heroContent.querySelector('.Button')
      const b = this.$refs.heroContent.querySelector('.ButtonLink')
      const l = this.$refs.hero.querySelector('.Login')

      const options = {
        opacity: 1,
        translateY: 0,
        ease: 'power2'
      }

      let animationSpeed = isAnimated ? 0.5 : 0

      return t.to(h, animationSpeed, options, 1.5)
              .to(lb, animationSpeed, options, '-=0.3')
              .to(b, animationSpeed, options, '-=0.3')
              .to(l, animationSpeed, {
                opacity: 1,
                translateX: 0,
                ease: 'power2'
              }, '-=0.2')
    },

    gFadeInBottom (isVisible, entry) {
      if (isVisible) {
        this.startTween(entry.target, 0.5, {
          opacity: 1,
          translateY: 0,
          delay: 0.5,
          ease: 'power2'
        })
      }
    },

    gFadeIn (isVisible, entry) {
      if (isVisible) {
        this.startTween(entry.target, 0.3, {
          opacity: 1,
          delay: 0.5
        })
      }
    },

    addVisibility (isVisible, entry) {
      if (isVisible) entry.target.classList.add('is-visible')
    },

    gsapOptions (callback, threshold) {
      return {
        callback,
        once: true,
        intersection: {
          threshold
        }
      }
    },

    startTween (target, duration, ops) {
      gsap.to(target, duration, ops)
    },

    scrollToUsers () {
      this.$scrollTo('#register', 500, {
        easing: 'linear',
        cancelable: false
      })
    },

    scrollToBottom () {
      this.$scrollTo('#register', 15000, {
        easing: 'linear'
      })
    },

    scrollUp () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';
.popper-linklist {
   /* use as much space as possible, and remove some pixels because of positioning (so that hte whole list is visible) */
   /* also leave enough space for the header, so popper does not reposition the popover element automatically */
  max-height: calc(50vh);
  overflow-y: auto;
}

.HomeView {
  .Hero {
    position: relative;
    background: $color-grey-100;
    height: 100vh;

    .Headline {
      max-width: 500px;
      margin-bottom: .8em;
    }

    .arrow {
      border: 0;
      background: transparent;
      outline: 0;
      -webkit-appreance: none;
      position: absolute;
      left: 50%;
      bottom: 50px;
      transform: translate3d(-50%, 0, 0);
      backface-visibility: none;
      z-index: 10;
      animation: MoveArrowUpDown 1s $transition-curve-standard infinite;
      width: 40px;
      cursor: pointer;

      img {
        width: 100%;
      }
    }

    .container {
      position: absolute;
      left: 0;
      bottom: 100px;
      z-index: 1;
      width: 100%;

      @include media('>=md') {
        width: 100%;
        left: 100px;
        transform: translate(-50px, 0);
      }

      @include media('>=lg') {
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    .login-mobile {
      width: 100%;
      max-width: 280px;
      display: block;
      margin-bottom: 10px;
      margin-right: 0 !important;

      @include media('>=md') {
        display: none;
      }
    }

    .Button {
      margin-right: 30px;
    }

    a {
      color: $white;
      &.register-link {
        color: $color-red-500;
        text-decoration: none;
      }
    }

    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60%;
      background: $gradient-black-transparent;
    }
  }

  .HeroVideo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .medel-logo {
    position: absolute;
    right: 75px;
    top: 75px;
    height: 34px;
    z-index: 10;
  }

  .Intro {
    position: relative;
    padding: 10em 0 2em;
    text-align: center;
    background: $gradient-grey-100-0;

    .logo {
      max-height: 45px;
      width: auto;
      margin-bottom: 3em;
    }

    .teaser {
      width: 60%;
      margin: 0 auto;
      max-width: 620px;
    }

    @include media('>=lg') {
      padding-bottom: 3em;
    }
  }

  .container-path {
    width: 100%;
    position: relative;
    padding: 0 15px;

    @include media('>=xs') {
      transform: translate(10px, 0);
      max-width: 435px;
      margin: 0 auto;
    }

    @include media('>=sm') {
      max-width: 490px;
    }

    @include media('>=md') {
      max-width: unset;
      text-align: center;
      transform: translate(0, 0);
    }
  }

  .Benefits {
    position: absolute;
    top: 250px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 100%;
    text-align: center;

    .list {
      list-style: none;
      display: flex;
      justify-content: space-around;
      margin-top: 58px;
      flex-direction: column;

      li {
        @include animation-out;
        transition-delay: 0.3s;
      }

      li:nth-child(2) {
        transition-delay: 0.6s;
      }

      li:nth-child(3) {
        transition-delay: 0.9s;
      }

      li.is-visible {
        opacity: 1;
        transform: translate3d(0,0,0);
      }
    }

    @include media('>=md') {
      top: 365px;
      max-width: 768px;

      .list {
        flex-direction: row;
      }
    }

    @include media('>=lg') {
      max-width: 1024px;
      top: 430px;

      .list {
        margin-top: 75px;
      }
    }

    @include media('>=max-width') {
      top: 470px;

      .list {
        margin-top: 100px;
      }
    }
  }

  .Mockups {
    position: relative;
    text-align: center;
    background: $gradient-grey-300-100;
    z-index: 1;

    .Headline {
      position: relative;
      margin-left: 60%;
      width: 40%;
      max-width: 400px;
      text-align: left;

      @include media('>=sm') {
        position: absolute;
        left: 55%;
        margin-left: 0;
      }
    }

    .has-mockups {
      transform: translate3d(0, 30px, 0);

      @include media('>=sm') {
        transform: translate3d(0, 100px, 0);
      }
    }

    .mockup {
      @include animation-out;
      transform: translate3d(0, 100px, 0);
      width: 100%;

      &.is-visible {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }

      &:nth-child(1) {
        transition-delay: 0.15s;
      }

      &:nth-child(2) {
        transition-delay: 0.3s;
      }

      &:nth-child(3) {
        transition-delay: 0.45s;
      }

      &:nth-child(4) {
        transition-delay: 0.6s;
      }

      &:nth-child(5) {
        transition-delay: 0.75s;
      }

      &.is-fixed {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .Users {
    position: relative;
    border-top: 3px solid $color-grey-500;
    text-align: center;
    margin-bottom: 10em;

    .path-container {
      width: 100%;
      padding-top: 15em;
      margin: 0 auto;
      max-width: 320px;

      @include media('>=xs') {
        max-width: 400px;
        margin: 0 auto;
      }

      @include media('>=sm') {
        max-width: 50%;
      }

      @include media('>=md') {
        padding-top: 0;
        max-width: inherit;
        text-align: center;
        transform: translate(0, 0);
      }

      @include media('>=lg') {
        max-width: 1120px;
      }

      @include media('>=xl') {
        max-width: 1380px;
      }

      @include media('>=max-width') {
        max-width: 1520px;
      }
    }

    .persona-wrapper {
      margin: 20px auto 0;
      justify-content: space-around;
      align-items: flex-end;
      flex-direction: column;
      padding-left: 50px;
      max-width: 350px;

      @include media('>=xs') {
        margin: 115px auto 0;
        padding-left: 30px;
      }

      @include media('>=sm') {
        margin-top: 120px;
        max-width: 550px;
        padding-left: 110px;
        // transform: translate(20px, 0);
      }

      @include media('>=md') {
        transform: translate(0, 0);
        margin-top: 0;
        justify-content: space-between;
        flex-direction: row;
        max-width: 864px;
        padding-left: 30px;
      }

      @include media('>=lg') {
        max-width: 1120px;
      }

      @include media('>=xl') {
        max-width: 1380px;
      }

      @include media('>=max-width') {
        max-width: 1520px;
      }
    }

    .Headline.is-size-2 {
      position: relative;
      left: 50%;
      padding: 16px 0 8px;
      text-align: center;
      background-color: $color-grey-100;
      width: 320px;
      margin-left: -160px;
      top: -100px;

      @include media('>=md') {
        position: absolute;
        top: 150px;
        margin-left: -250px;
        width: 500px;
      }
    }

    .Persona {
      &:nth-of-type(2) {
        transition-delay: 0.15s;
      }

      &:nth-of-type(3) {
        transition-delay: 0.3s;
      }
    }

    .path-wrapper {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      height: 1000px;

      @include media('>=md') {
        position: relative;
        height: 425px;
      }

      @include media('>=lg') {
        position: relative;
        height: 540px;
      }

      @include media('>=max-width') {
        height: 810px;
      }
    }

    @include media('>=sm') {
      margin-bottom: 15em;
    }

    @include media('>=max-width') {
      .wrapper {
        justify-content: space-around;
      }
    }
  }

  .path-mobile {
    display: inline;
    pointer-events: none;

    path {
      transition: all 0.2s $transition-curve-sharp;
      transform: scaleZ(1);
      backface-visibility: hidden;
    }

    &.is-first {
      max-width: 100%;
      height: 1400px;
      margin-left: 50px;
    }

    &.is-last {
      max-height: 100%;
    }

    @include media('>=md') {
      display: none;
      visibility: hidden;
    }
  }

  .path-desktop {
    display: none;
    pointer-events: none;

    path {
      transition: all 0.2s $transition-curve-sharp;
      transform: scaleZ(1);
      backface-visibility: hidden;
    }

    @include media('>=md') {
      display: inline;
    }

    &.is-first {
      max-width: 100%;
      max-height: 1100px;

      @include media('>=lg') {
        max-height: 1300px;
      }

      @include media('>=max-width') {
        max-height: 1440px;
      }
    }

    &.is-last {
      position: absolute;
      top: 0;
      height: 100%;
      width: auto;
    }

    &.is-left {
      right: 50%;
      transform: translate3d(7px, 0, 0);

      @include media('>=lg') {
        transform: translate3d(9px, 0, 0);
      }
    }

    &.is-right {
      left: 50%;
      transform: translate3d(0, 0, 0);
    }
  }

  // language switch wrapper
  .v-popper {
    position: absolute;
    right: 50px;
    top: 110px;
    width: 60px;

    &.open .language-switch::after {
      transform: translate(0, -50%) rotate(180deg);
    }

    .language-switch {
      position: relative;
      text-transform: capitalize;
      padding: 10px 20px 10px 0;
      color: $white;
      cursor: pointer;

      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translate(0, -50%);
        border-top: 5px solid $white;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
      }
    }
  }
}
</style>
