<template>
    <sweet-modal
      ref="emailVerifiedMessage"
      icon="info"
      overlay-theme="dark"
    >
      <div class="termsOfUseContent">
        <h1 class="Headline is-size-4" v-t="'emailVerifiedMessage.title'" />
        <p  v-html="$t('emailVerifiedMessage.infoMessage')" >
        </p>
      </div>
        <div class="row">
            <button type="button" class="Button" @click="closeEmailVerifiedMessage">
              {{ $t('emailVerifiedMessage.closeButtonText') }}
            </button>
        </div>
    </sweet-modal>
</template>

<script>
import { login } from '../auth/auth0'

export function isEmailVerifiedParameterInRoute (route) {
    return route && route.query && route.query['displayMessage'] === 'emailVerified'
}

export function removeEmailVerifiedParameterFromRoute (route, router) {
    // remove the query parameter, because otherwise  the user will see this on every page navigation/reloading the page
    const query = Object.assign({}, route.query)
    delete query.displayMessage
    router.replace({ 'query': query })
}

export function removeEmailVerifiedParameterFromUrlString (url) {
    return url.replace('displayMessage=emailVerified', '')
}

export default {
  name: 'EmailVerifiedMessage',
  data () {
    return {
    }
  },

  computed: {
  },

  methods: {
    async closeEmailVerifiedMessage () {
      this.$refs.emailVerifiedMessage.close()
      if (!this.$auth0?.isAuthenticated?.value) {
        login()
      }
    },

    open () {
      this.clearRouteQueryParams()
      this.$refs.emailVerifiedMessage.open()
    },

    shouldShow () {
      return isEmailVerifiedParameterInRoute(this.$route)
    },

    clearRouteQueryParams () {
      removeEmailVerifiedParameterFromRoute(this.$route, this.$router)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.linkButton, .linkButton:hover{
  background:none;
  color:#979594;
}

.row {
  margin-top: 10px;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}
</style>
