export default {
  computed: {
    // returns translation object based on current locale
    localeMessages () {
      const langOptions = this.$root.$options.i18n

      return langOptions.messages[langOptions.locale]
    }
  },
  methods: {
    translateWithMymedelString (key) {
      if (!key) return key
      var translation = this.$t(key)
      return translation.replace(/mymed-el/ig, '<span style="white-space: nowrap">myMED-EL</span>')
    }
  }
}
