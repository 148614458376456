import { getOptionsAsObjectFromTranslation } from '../../translations'

// initial state
const state = {
  editable: false,
  type: null
}

// getters
const getters = {
  editable: state => state.editable,
  type: state => state.type,
  userTypes: (state) => getOptionsAsObjectFromTranslation('form.userRoles')
}

// actions, async changes (api calls, ...)
const actions = {
}

// mutations, sync changes
const mutations = {
  setEditable (state, value) {
    state.editable = value
  },
  setType (state, value) {
    state.type = value
  },
  resetType (state) {
    let userType = this.getters['user/userType']
    if (!userType) {
      state.type = null
      return
    }
    let userTypeObject = this.getters['profile/userTypes'].find(x => x.code.toLowerCase() === userType.toLowerCase())
    state.type = userTypeObject
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
